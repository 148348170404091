import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import st from "./about.module.scss"

export default () => (
  <Layout>
    <SEO title="About" />
    <header className={st.header}>
      <h1 className={st.sectionHeading}>About</h1>
    </header>
    <p
      style={{
        textAlign: "center",
      }}
    >
      Welcome to my website.
    </p>
  </Layout>
)
